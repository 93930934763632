import { combineReducers } from 'redux';
import configReducer from './configReducer';
// Import other reducers here

const rootReducer = combineReducers({
  config: configReducer,
  // other reducers
});

export default rootReducer;
