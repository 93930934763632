import React from "react";
import ReactDOM from "react-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import { Provider } from 'react-redux';

import AppRoutes from './routes';
import store from 'stores/index';

const loadAppSettings = async () => {
  const response = await fetch('/appsettings.json');
  const config = await response.json();
  
  window.appSettings = config; // Store config in a global variable
};


ReactDOM.render(
  <Provider store={store}>
    <AppRoutes/>
  </Provider>,
  document.getElementById("root")
);
