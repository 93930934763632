// Action types
export const SET_CONFIG = 'SET_CONFIG';

// Action creator to set the configuration
export const setConfig = (config) => ({
  type: SET_CONFIG,
  payload: config,
});

// Async action to load the configuration
export const loadConfig = () => {
  return async (dispatch) => {
    try {
      const response = await fetch('/appsettings.json'); // Load config from the public folder
      const config = await response.json();
      console.log(config);
      dispatch(setConfig(config)); // Store config in Redux state
    } catch (error) {
      console.error('Error loading config:', error);
    }
  };
};
