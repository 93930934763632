import { SET_CONFIG } from './configActions';

const initialState = {
  apiUrl: '',
  otherSetting: '',
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIG:
      return { ...state, ...action.payload }; // Update state with the config
    default:
      return state;
  }
};

export default configReducer;
